import React from "react"
import { Link } from "gatsby"
import LogoFooter from "../svgs/logo-footer.svg"
import PropTypes from "prop-types"

const Footer = ({ siteTitle }) => {
  return (
    <footer id="footer" className="section footer">
      <div className="section__inner">
        <Link to="/" title={siteTitle} className="footer__logo-wrapper">
          <LogoFooter className="footer__logo-image" />
        </Link>
        <p className="text1">
          <Link to="/terms">Terms of use</Link>
        </p>
        <p className="text2">© {new Date().getFullYear()} SizePipe AB</p>
        <p className="text2">
          Box 3112
          <br />
          411 12 Gothenburg
          <br />
          Sweden
        </p>
        <a href="info@sizepipe.com">info@sizepipe.com</a>
      </div>
    </footer>
  )
}

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: "",
}

export default Footer
