import React, { useState, useContext } from "react"
import Axios from "Axios"
import {
  GlobalStateContext,
  GlobalDispatchContext,
} from "../context/globalState"

const OrderModal = () => {
  const state = useContext(GlobalStateContext)
  const dispatch = useContext(GlobalDispatchContext)
  const [formState, setFormState] = useState({
    isSingleLicense: false,
    isOrganizationLicense: true,
    organizationName: "",
    emailInvoice: "",
    referenceOnInvoice: "",
    otherInvoiceInfo: "",
    country: "",
    vatNumber: "",
    submitted: false,
  })

  React.useEffect(() => {
    if (state.plan === "organization") {
      setFormState({
        ...formState,
        isSingleLicense: false,
        isOrganizationLicense: true,
      })
    }
    if (state.plan === "single") {
      setFormState({
        ...formState,
        isSingleLicense: true,
        isOrganizationLicense: false,
      })
    }
  }, [state.plan])

  const hideOrderModal = (e) => {
    e.preventDefault()
    dispatch({ type: "HIDE_ORDER_MODAL" })
  }

  const modalStyle = {
    display: state.showOrderModal ? "block" : "none",
  }

  const handleInputChange = (e) => {
    const target = e.target
    const value = target.value
    const name = target.name
    setFormState({
      ...formState,
      [name]: value,
    })
  }
  const handleRadio = (e) => {
    const target = e.target
    const value = target.value
    const name = target.name
    if (value === "single")
      setFormState({
        ...formState,
        isSingleLicense: true,
        isOrganizationLicense: false,
      })
    else
      setFormState({
        ...formState,
        isSingleLicense: false,
        isOrganizationLicense: true,
      })
    return true
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    try {
      Axios.post("/api_sp/order", {
        order: { ...formState },
      })
    } catch (err) {}
    setFormState({
      ...formState,
      submitted: true,
    })
  }

  if (state.isLoggedin)
    return (
      <div id="order_modal" className="order-modal section" style={modalStyle}>
        <button className="close-modal" onClick={hideOrderModal}>
          ✖
        </button>
        <div className="section__inner">
          {formState.submitted === false && (
            <>
              <h3 className="heading1">Place order</h3>
              <p className="text1">
                Place your single or organization license order below
              </p>
              <form
                className={
                  formState.submitted
                    ? " order-modal__form order-modal__form--hidden"
                    : "order-modal__form"
                }
                onSubmit={handleSubmit}
              >
                <label className="order-modal__form-label">
                  <input
                    className="order-modal__form-radio"
                    type="radio"
                    name="license"
                    value="single"
                    onChange={handleRadio}
                    checked={formState.isSingleLicense}
                  />
                  Single license
                </label>
                <label className="order-modal__form-label">
                  <input
                    className="order-modal__form-radio"
                    type="radio"
                    name="license"
                    value="org"
                    onChange={handleRadio}
                    checked={formState.isOrganizationLicense}
                  />
                  Organization license (unlimited licenses)
                </label>
                <label className="order-modal__form-label">
                  Oragnization*
                  <input
                    className="order-modal__form-text"
                    type="text"
                    name="organizationName"
                    value={formState.organizationName}
                    onChange={handleInputChange}
                    required
                  />
                </label>
                <label className="order-modal__form-label">
                  Country*
                  <input
                    className="order-modal__form-text"
                    type="text"
                    name="country"
                    value={formState.country}
                    onChange={handleInputChange}
                    required
                  />
                </label>
                <label className="order-modal__form-label">
                  VAT number
                  <input
                    className="order-modal__form-text"
                    type="text"
                    name="vatNumber"
                    value={formState.vatNumber}
                    onChange={handleInputChange}
                  />
                </label>

                <label className="order-modal__form-label">
                  Email*
                  <input
                    className="order-modal__form-text"
                    type="email"
                    name="emailInvoice"
                    value={formState.emailInvoice}
                    onChange={handleInputChange}
                    required
                  />
                </label>
                <label className="order-modal__form-label">
                  Reference on Invoice
                  <input
                    className="order-modal__form-text"
                    type="text"
                    name="referenceOnInvoice"
                    value={formState.referenceOnInvoice}
                    onChange={handleInputChange}
                  />
                </label>
                <label className="order-modal__form-label">
                  Other invoice info
                  <textarea
                    className="order-modal__form-textarea"
                    name="otherInvoiceInfo"
                    onChange={handleInputChange}
                    value={formState.otherInvoiceInfo}
                  />
                </label>
                <p className="text1">
                  €{formState.isSingleLicense ? "200" : "650"}/year &nbsp;
                  <button className="cta1" type="submit">
                    Send
                  </button>
                </p>
              </form>
            </>
          )}
          {formState.submitted && (
            <>
              <p className="heading2">
                Thank you for your order! You now have full access to Sizepipe
              </p>
              <a
                href="https://sizepipe.com/app/#/app/calculator"
                className="cta1"
              >
                Launch SizePipe!
              </a>
            </>
          )}
        </div>
      </div>
    )
  return null
}

export default OrderModal
