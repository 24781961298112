import React, { useState, useContext } from "react"
import Axios from "Axios"
import { Link } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import PropTypes from "prop-types"
import Logo from "../svgs/logo.svg"
import UserDropdown from "./user-dropdown"

import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../context/globalState"

const Header = ({ siteTitle }) => {
  const dispatch = useContext(GlobalDispatchContext)
  const state = useContext(GlobalStateContext)

  React.useEffect(() => {
    try {
      const fetchData = async () => {
        const userDataFetch = await Axios.get("/api_sp/user/loggedin")
        dispatch({
          type: "LOG_IN",
          payload: {
            userData: userDataFetch.data,
          },
        })
      }
      fetchData()
    } catch (err) {}
  }, [])

  const [menuState, setMenuState] = useState("")

  const toggleMenu = () => {
    setMenuState(menuState === "" ? " menu-visible" : "")
  }
  const closeMenu = () => {
    setMenuState("")
  }

  const toggleLonginState = (e) => {
    e.preventDefault()
    dispatch({ type: state.isLoggedin ? "LOG_OUT" : "LOG_IN" })
  }

  return (
    <header id="header" className={`header${menuState}`}>
      <Link to="/" title={siteTitle} className="header__logo-wrapper">
        <Logo className="header__logo-image" onClick={closeMenu} />
      </Link>

      <ul className="header__menu" onClick={closeMenu}>
        <li className="header__menu-item">
          <AnchorLink className="text1" to="/#info">
            Features
          </AnchorLink>
        </li>
        <li className="header__menu-item">
          <AnchorLink className="text1" to="/#pricing">
            Pricing
          </AnchorLink>
        </li>

        {!state.isLoggedin ? (
          <li className="header__menu-item">
            <a href="https://sizepipe.com/app/#/login" className="text1">
              Log in
            </a>
          </li>
        ) : (
          ""
        )}

        <li className="header__menu-item">
          {state.isLoggedin ? (
            <UserDropdown />
          ) : (
            <a className="cta2" href="https://sizepipe.com/app/#/signup">
              Signup
            </a>
          )}
        </li>
      </ul>

      <button onClick={toggleMenu} className="header__menu-button"></button>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: "",
}

export default Header
