import React, { useContext } from "react"
import Axios from "Axios"

import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../context/globalState"

const UserDropdown = () => {
  //use for user data later as well
  const state = useContext(GlobalStateContext)
  const dispatch = useContext(GlobalDispatchContext)

  const logOut = (e) => {
    e.preventDefault()
    Axios.post("/api_sp/logout")
    dispatch({
      type: "LOG_OUT",
    })
  }

  return (
    <div className="user-dropdown">
      <a href="#" className="text1">
        {state.userData.name}
      </a>
      <div className="user-dropdown__hidden">
        <a href="#" className="text1" onClick={logOut}>
          Log out
        </a>
      </div>
    </div>
  )
}

export default UserDropdown
