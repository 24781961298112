/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useContext } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Bowser from "bowser"

import Header from "./header"
import Footer from "./footer"
import OrderModal from "./order-modal"
import "../styles/_init.scss"
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../context/globalState"

const Layout = ({ children, location }) => {
  const [iewarning, setIEWarning] = React.useState()

  const state = useContext(GlobalStateContext)
  const dispatch = useContext(GlobalDispatchContext)
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  React.useEffect(() => {
    const params = window.location.search
    const bowser = Bowser.getParser(window.navigator.userAgent)
    const browser = bowser.getBrowser()

    if (browser.name === "Internet Explorer") {
      setIEWarning(true)
    }

    if (params != null) {
      const param = params.split("?")[1]
      if (param != null) {
        const plan = param.split("=")[1]
        if (plan === "organization" || plan === "single") {
          dispatch({
            type: "SHOW_ORDER_MODAL",
            payload: {
              plan: plan,
            },
          })
        }
      }
    }
  }, [])

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      {iewarning && (
        <div
          style={{
            position: "fixed",
            top: 0,
            width: "100%",
            zIndex: 300000,
            backgroundColor: "red",
            height: "300px",
            padding: "40px",
            fontSize: "48px",
            textTransform: "uppercase",
            color: "white",
            lineHeight: "1",
            fontWeight: "bold",
          }}
        >
          Warning: Internet Explorer is not supported! Please use a modern
          browser.
        </div>
      )}
      <main className={state.showOrderModal ? "fade main" : "main"}>
        {children}
      </main>
      <Footer siteTitle={data.site.siteMetadata.title} />
      <OrderModal />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
